import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from "react-i18next";

class PrivacyNotice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
      return (<>
          <h1>{this.props.t('privacy_notice/title/privacy_notice')}</h1>
          <div>{this.props.t('privacy_notice/body')}</div>
        </>
      );
  }
}

export default withTranslation()(PrivacyNotice);