import React, {Component} from 'react';
import { Modal, Alert, Button, Form, Spinner } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { withTranslation } from "react-i18next";
import { withRouter } from '../../util/Router';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeInput: '',
      passwordInput: '',
      usernameError: false,
      codeError: false,
      otherError: false,
      changed: false,
      validated: false,
      spinning: false,
    };
  }

  handleCodeInput = (event) => {
    this.setState({codeInput: event.target.value});
  }

  handlePasswordInput = (event) => {
    this.setState({passwordInput: event.target.value});
  }

  setUsernameError = (error) => {
    this.setState({usernameError: error});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setCodeError = (error) => {
    this.setState({codeError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setChanged = (changed) => {
    this.setState({changed: changed});
  }

  validateForm = (event) => {
    event.preventDefault();
    const form = this.formRef;
    if  (form.checkValidity() === false) {
      event.stopPropagation();
    }

    this.setChanged(false)
    this.setOtherError(false)
    this.setValidated(true);
    this.verify()
  }

  verify = async (event) => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      Auth.forgotPasswordSubmit(this.props.params.email, this.state.codeInput, this.state.passwordInput)
      .then((response) => {
        this.setSpinning(false);
        this.setChanged(true);
        this.props.onChanged();
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {

    var submitButton = <Button variant="primary"  className="my-1 mr-2" type="submit">{this.props.t('button/label/change_password')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary"  className="my-1 mr-2" type="submit" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/change_password')}</span>
        </Button>
    }

    var form = <><p>{this.props.t('change_password/description/enter_code_from_email')}</p>
      
      <Form.Group controlId="group-name">
        <Form.Label className="my-1 mr-sm-2" className="required">{this.props.t('form/label/code')}</Form.Label>
        <Form.Control
          required
          type="text"
          className="my-1 mr-sm-2"
          placeholder={this.props.t('form/placeholder/code')}
          name="code"
          value={this.state.codeInput}
          onChange={this.handleCodeInput}
        />
      </Form.Group>
      <Form.Group controlId="group-password">
        <Form.Label className="my-1 mr-2" className="required">{this.props.t('form/label/new_password')}</Form.Label>
        <Form.Control
          required
          type="password"
          className="my-1 mr-2"
          placeholder={this.props.t('form/placeholder/new_password')}
          name="password"
          value={this.state.passwordInput}
          onChange={this.handlePasswordInput}
        />
      </Form.Group>
      </>

    if(this.state.changed ) {
      form = ''
      submitButton = ''
    }

    return <Modal show={this.props.opened} onHide={this.props.onClose} centered>
      <Form noValidate validated={this.state.validated} ref={(formRef) => { this.formRef = formRef }} onSubmit={this.validateForm}>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.t('change_password/title/change_password')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='success' show={this.state.changed}>
          {this.props.t('change_password/success_alert/password_changed')}
        </Alert>
        <Alert variant='danger' show={this.state.codeError}>
          {this.props.t('change_password/failure_alert/incorrect_code')}
        </Alert>
        <Alert variant='danger' show={this.state.otherError}>
          {this.props.t('failure_alert/general_error')}
        </Alert>
        {form}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onClose}>
          {this.props.t('button/label/close')}
        </Button>
        {submitButton}
      </Modal.Footer>
      </Form>
    </Modal>
  }
}

export default withTranslation()(withRouter(ChangePassword));