import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import { Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { IoMdPerson, IoMdLogOut, IoMdLogIn, IoMdLock, IoMdBusiness, IoMdAdd, IoMdCheckmark, IoMdSettings} from "react-icons/io";

class Sidebar extends Component {
  constructor(props) {
    super(props); 
  }

  handleMenu = (selectedKey) => {
    switch(selectedKey) {
      case 'item':
        break;
    }
  }
 
  render() {
    return (
      <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Link as={Link} to="/activities">{this.props.t("navigation/link/activities")}</Nav.Link>
        <Nav.Link as={Link} to="/calendar" disabled>{this.props.t("navigation/link/calendar")}</Nav.Link>
        <Nav.Link as={Link} to="/participants" disabled>{this.props.t("navigation/link/participants")}</Nav.Link>
        <Nav.Link as={Link} to="/reports" disabled>{this.props.t("navigation/link/reports")}</Nav.Link>
      </Nav>
    );
  }
}

Sidebar.propTypes = {
  organisation: PropTypes.object
};

export default withTranslation()(Sidebar);