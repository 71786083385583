import React, {Component} from 'react';
import { Button, Form, Alert, Col, Spinner, Row, Container, Card } from 'react-bootstrap';

class Pane extends Component {
  constructor(props) {
    super(props); 
  }
 
  render() {
    return (
    <section className="container my-3">
      <Card>
        <Card.Body>
          <Card.Title>{this.props.title}</Card.Title>
          <Card.Text>
          <div className="pane-controls my-3">
            {this.props.operations}
          </div>
          <div className="pane-content my-3">
            {this.props.value}
          </div>
          <div className="pane-footer text-end">
            {this.props.footer}
          </div>
          </Card.Text>
        </Card.Body>
    </Card>
    </section>
    );
  }
}

export default Pane;