import React, {Component} from 'react';
import { Table } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";
import { withRouter } from '../../util/Router';
import { Pane } from '../../components/Pane';

import { Loading } from '../../components/Loading'

// AWS Amplify
import { API } from 'aws-amplify';
class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      loading: false,
      spinning: false,
      deleteSpinning: false,
      aliasExists: false,
      otherError: false,
      validated: false,
      updated: false
    };
  }

  async componentDidMount() {
    if (this.props.organisationId) {
      await this.refreshActivities()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.organisationId !== prevProps.organisationId) {
      await this.refreshActivities()
    }

    if (this.props.timestamp !== prevProps.timestamp) {
      await this.refreshActivities()
    }
  }

  refreshActivities = async () => {
    if (this.props.organisationId) {
      this.setLoading(true)
      await API.get('activity', '/organisation/' + this.props.organisationId + '/activity', {})
      .then(response => {
        this.setLoading(false)
        this.setState({activities: response});
      })
      .catch(error => {
        this.setLoading(false)
        console.log(error)}
      )
    }
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setDeleteSpinning = (spinning) => {
    this.setState({deleteSpinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  validateForm = (event) => {
    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setValidated(true);
    this.save()
  }

  editActivity = (activity) => {
    this.props.navigate("/activities/" + activity.activityId + "/edit")
  } 

  delete = async () => {
    this.setDeleteSpinning(true);
  }

  render() {
    const activities = this.state.activities.map((activity) =>
      <tr key={activity.activityId} onClick={() => this.editActivity(activity)}>
        <td>{activity.name}</td>
        <td>{activity.type}</td>
        <td></td>
        <td>{activity.location.country}</td>
      </tr>
    );

    const loading = <Loading/>

    const pane = <><Pane 
      title={this.props.t('activities/title/activities')}
      operations={<>
        <Link className="btn btn-success" to={`new`}>{this.props.t('button/label/add_activity')}</Link></>
      }
      value={<>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Typ</th>
              <th>Daty</th>
              <th>Lokalizacja</th>
            </tr>
          </thead>
          <tbody>
            {activities}
          </tbody>
        </Table>
        </>}
      /><Outlet/></>

    return this.state.loading ? loading : pane
  }
}

export default withTranslation()(withRouter(Activities))