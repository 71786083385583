import React, {Component} from 'react';
import { Button, Form, Alert, Col, Spinner, Row, Container, Card } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailInput: '',
      passwordInput: '',
      spinning: false,
      userExistsError: false,
      otherError: false,
      validated: false,
      validationError: false,
    };
  }

  resetForm = () => {
    this.setState({
      emailInput: '',
      passwordInput: '',
      spinning: false,
      userExistsError: false,
      otherError: false,
      validated: false,
      validationError: false,
    });
  }

  handleEmailInput = (event) => {
    this.setState({emailInput: event.target.value});
  }

  handlePasswordInput = (event) => {
    this.setState({passwordInput: event.target.value});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setUserExistsError = (exists) => {
    this.setState({userExistsError: exists});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  validateForm = (event) => {
    event.preventDefault();
    const form = this.formRef;
    if  (form.checkValidity() === false) {
      event.stopPropagation();
    }

    this.setUserExistsError(false)
    this.setOtherError(false)
    this.setValidated(true);
    this.signUp()
  }

  signUp = (event) => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);

      Auth.signUp({
        username: this.state.emailInput,
        password: this.state.passwordInput,
        attributes: {
            email: this.state.emailInput,
        }
      })
      .then((response) => {
        this.props.onSignUp(this.state.emailInput);
        this.resetForm();
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          case "UsernameExistsException":
            this.setUserExistsError(true)
            break;
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {
    var submitButton = <Button variant="primary" type="submit">{this.props.t('button/label/sign_up')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" disabled><Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/sign_up')}</span>
        </Button>
    }

    return <><Container className="sign-up py-5">
    <Card>
      <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} className="justify-content-center sign-up" ref={(formRef) => { this.formRef = formRef }}>
        <Card.Body>
          <Card.Title>{this.props.t('sign_up/title/sign_up')}</Card.Title>
          <Card.Text>
            <Alert variant='danger' show={this.state.otherError}>
              {this.props.t('failure_alert/general_error')}
            </Alert>
            <Alert variant='danger' show={this.state.validationError}>
              {this.props.t('failure_alert/validation_error')}
            </Alert>
            <Alert variant='danger' show={this.state.userExistsError}>
              {this.props.t('sign_up/failure_alert/user_exists')}
            </Alert>
            <p>{this.props.t('sign_up/description')}</p>
            <Row className="justify-content-center form-items">
              <Form.Group as={Col} xs={12} lg={6} controlId="group-email">
                <Form.Label className="required">{this.props.t('form/label/email')}</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder={this.props.t('form/placeholder/email')}
                  value={this.state.emailInput}
                  onChange={this.handleEmailInput}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6} controlId="group-password">
                <Form.Label className="required">{this.props.t('form/label/password')}</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder={this.props.t('form/placeholder/password')}
                  value={this.state.passwordInput}
                  onChange={this.handlePasswordInput}
                />
              </Form.Group>
            </Row>
          </Card.Text>
          <p>{this.props.t('sign_up/description/agreeing_to_privacy_notice')} <Link to="/privacy-notice"> {this.props.t('navigation/link/privacy_notice')}</Link>.</p>
          {submitButton}
        </Card.Body>
          <Card.Footer>
            {this.props.t('form/label/or_sign_in')} <Link to="/signin">{this.props.t('navigation/link/sign_in')}</Link>.
          </Card.Footer>
      </Form>
    </Card>
</Container>
<Outlet/></>
  }
}

SignUp.propTypes = {
  onSignUp: PropTypes.func
};

export default withTranslation()(SignUp)