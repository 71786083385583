import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
      return (
        <Row>
          <Col xs={12} sm={6}>
            &copy; {this.props.t('copyrights_notice')}
          </Col>
          <Col xs={12} sm={6} className="text-sm-end">
            <Link to="/privacy-notice"> {this.props.t('navigation/link/privacy_notice')}</Link>
          </Col>
        </Row>
      );
  }
}

export default withTranslation()(Footer);