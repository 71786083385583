import React, {Component} from 'react';
import { Spinner } from 'react-bootstrap';
import { withTranslation } from "react-i18next";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '10vh'}}><Spinner animation="grow"/></div>
  }
}

export default withTranslation()(Loading)