import React, {Component} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from "react-i18next";

class Confirm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Modal show={this.props.opened} onHide={this.props.onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.t('confirm/title/confirm')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{this.props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onCancel}>
          {this.props.t('button/label/cancel')}
        </Button>
        <Button variant="danger" onClick={this.props.onConfirm}>
          {this.props.t('button/label/confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  }
}

export default withTranslation()(Confirm)