import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from "react-i18next";

class About extends Component {
  constructor(props) {
    super(props);
  }

  render() {
      return (<>
          <h1>{this.props.t('about')}</h1>
      </>
      );
  }
}

export default withTranslation()(About);