import React, {Component} from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

class Empty extends Component {
  constructor(props) {
    super(props); 
  }
 
  render() {
    return (
    <section className="container my-3">
      <div className="pane-content my-3 text-center">
        {this.props.message}
      </div>
      <div className="pane-footer text-center">
        <Link to={this.props.ctaLink}><Button variant="primary" type="submit">{this.props.cta}</Button></Link>
      </div>
    </section>
    );
  }
}

export default Empty;