import React, {Component} from 'react';
import { withTranslation } from "react-i18next";

// Router
import { Routes, Route, Link } from "react-router-dom";
import { withRouter } from '../../util/Router';

// Comps
import { Navbar, Container, Button } from 'react-bootstrap';

import { RemindPassword } from '../../components/RemindPassword'
import { SignIn } from '../../components/SignIn'
import { SignUp } from '../../components/SignUp'
import { MyAccount } from '../../components/MyAccount'
import { MyOrganisation } from '../../components/MyOrganisation'
import { Activities } from '../../components/Activities'
import { Activity } from '../../components/Activity'
import { ActivityCalendar } from '../../components/ActivityCalendar'
import { ValidateEmail } from '../../components/ValidateEmail'
import { ChangePassword } from '../../components/ChangePassword'

import { About } from '../../components/About'
import { PrivacyNotice } from '../../components/PrivacyNotice'
import { Contract } from '../../components/Contract'

import { TopNavigation } from '../../components/TopNavigation'
import { Footer } from '../../components/Footer'
import { Sidebar } from '../../components/Sidebar'
import { Pane } from '../../components/Pane'
import { Empty } from '../../components/Empty'

// Icons
import { IconContext } from "react-icons";
import logo from './resources/logo.svg'; 

// AWS Amplify
import { Auth, Hub, API } from 'aws-amplify';

// Styles & resources
import './scss/layout.scss';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      mustSignContract: false,
      mustCreateOrganisation: false,
      loading: false,
      user: {},
      organisations: [],
      activeOrganisation: null,
      activitiesTimestamp: Date.now()
    }

    // Listen to AWS Amplify Auth events to update state when necessary
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          let user = data.payload.data;
          this.setState({
            authenticated: true,
            mustSignContract: user.attributes['custom:signed_contract'] == 'true' ? false : true,
            user: user})
          this.refreshOrganisations()
          break;
        case 'signOut':
          this.setState({
            authenticated: false,
            mustSignContract: false,
            user: false})
          break;
        default:
          break;
      }
    });
  }

  async componentDidMount() {
    await this.refreshUser()
    if (this.state.authenticated) {
      await this.refreshOrganisations()
    }
  }

  refreshOrganisations = async () => {
    await API.get('organisation', '/organisation', {})
    .then(response => {
      if (response.length > 0) {
        var organisations = new Map();
        var activeOrganisation = null;

        // Expand organisations to map
        response.forEach((organisation, index) => {
          organisations.set(organisation.organisationId, organisation)
        });

        // Establish active organisation if it exists in local storage
        const activeOrganisationId = window.localStorage.getItem('activeOrganisationId');
        if (activeOrganisationId) {
          activeOrganisation = organisations.get(activeOrganisationId)
        }

        // Default to first org returned by the service.
        if (!activeOrganisation) {
          activeOrganisation = response[0]
        }

        this.setState({mustCreateOrganisation: false})
        this.setState({organisations: organisations})
        this.setState({activeOrganisation: activeOrganisation});
      }
      else {
        this.setState({mustCreateOrganisation: true})
        this.setState({organisations: []})
        this.setState({activeOrganisation: null});
      }
    })
    .catch(error => {console.log(error)})
  }

  refreshUser = async (bypassCache = false) => {
    await Auth.currentAuthenticatedUser({bypassCache: bypassCache})
      .then(user => {
        this.setState({
          authenticated: true, 
          mustSignContract: user.attributes['custom:signed_contract'] == 'true' ? false : true,
          user: user})
      });
  }

  signIn = () => {
    this.props.navigate("/")
  }

  confirmSignUp = (email) => {
    this.props.navigate("/confirm-signup/" + email)
  }

  verifyEmail = (email) => {
    this.props.navigate("/account/verify-email/" + email)
  }

  changePassword = (email) => {
    this.props.navigate("/account/change-password/" + email)
  }

  updateUser = () => {
    this.refreshUser(true)
  }

  createOrganisation = (organisation) => {
    this.refreshOrganisations()
    this.changeOrganisation(organisation)
  }

  updateOrganisation = (organisation) => {
    this.refreshOrganisations()
  }

  deleteOrganisation = () => {
    this.refreshOrganisations()
    this.props.navigate("/")
  }

  changeOrganisation = (organisation) => {
    this.setState({activeOrganisation: organisation});
    window.localStorage.setItem('activeOrganisationId', organisation.organisationId);
    this.props.navigate("/activities")
  }

  createActivity = (activity) => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  updateActivity = (activity) => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  deleteActivity = () => {
    this.setState({activitiesTimestamp: Date.now()});
  }

  signOut = () => {
    Auth.signOut()
    this.props.navigate("/")
  }

  goHome = () => {
    this.props.navigate("/")
  }

  goToActivities = () => {
    this.props.navigate("/activities")
  }

  goToAccount = () => {
    this.props.navigate("/account")
  }

  goToCalendar = () => {
    this.props.navigate("/calendar")
  }

  render() {
    let contract = <></>
    if (this.state.mustSignContract) {
      contract = <Contract 
        opened={true} 
        user={this.state.user} 
        onUpdate={this.updateUser}/>
    }

    let sidebar
    if (this.state.authenticated && this.state.activeOrganisation) {
      sidebar = <Sidebar/>
    }
    else {
      sidebar = <></>
    }

    let content
    if (this.state.authenticated) {

      var needsOrganisation = '';
      if (this.state.activeOrganisation) {
        needsOrganisation = <>
          <Route path="/" element={<Pane 
            title={this.props.t('pane/title/activities')} 
            value={this.props.t('pane/message/activities')}
            footer={<Link to="/activities"><Button variant="primary" onClick={this.delete}>{this.props.t('button/label/manage_activites')}</Button></Link>}/>}/>
          <Route path="/activities" element={<Activities organisationId={this.state.activeOrganisation.organisationId} timestamp={this.state.activitiesTimestamp}/>}>
            <Route path={":activityId/edit"} element={<Activity 
              opened={true} 
              onUpdate={(activity) => this.updateActivity(activity)} 
              onDelete={this.deleteActivity} 
              onClose={this.goToActivities}
              organisationId={this.state.activeOrganisation.organisationId}/>}/>
            <Route path={"new"} element={<Activity 
              opened={true} 
              onCreate={(activity) => this.createActivity(activity)} 
              onClose={this.goToActivities} 
              organisationId={this.state.activeOrganisation.organisationId}/>}/>
          </Route>
          <Route path="/calendar" element={<ActivityCalendar organisationId={this.state.activeOrganisation.organisationId} timestamp={this.state.activitiesTimestamp}/>}>
            <Route path={":activityId/edit"} element={<Activity 
              opened={true} 
              onUpdate={(activity) => this.updateActivity(activity)} 
              onDelete={this.deleteActivity} 
              onClose={this.goToCalendar} 
              organisationId={this.state.activeOrganisation.organisationId}/>}/>
            <Route exact path={"new"} element={<Activity 
              opened={true} 
              onCreate={(activity) => this.createActivity(activity)} 
              onClose={this.goToCalendar} 
              organisationId={this.state.activeOrganisation.organisationId}/>}/>
            </Route>
        </>
      }
      else if (this.state.mustCreateOrganisation) {
        needsOrganisation = <>
          <Route path="/" element={<Empty message={this.props.t('layout/message/no_organisation')} cta={this.props.t('button/label/create_organisation')} ctaLink="/organisation/new"/>}/>
        </>
      }

      content = <>
          <Routes>
            {needsOrganisation}
            <Route path="/privacy-notice" element={<PrivacyNotice opened={true} onClose={this.goHome}/>}/>
            <Route path="/about" element={<About opened={true} onClose={this.goHome}/>}/>
            <Route path="/account" element={<MyAccount 
              user={this.state.user} 
              onUpdate={this.updateUser}
              onVerifyEmail={(email) => { this.verifyEmail(email) }}
              onChangeEmail={(email) => { this.verifyEmail(email) }}
              onChangePassword={(email) => { this.changePassword(email)}}/>}>
              <Route path={"change-password/:email"} element={<ChangePassword 
                opened={true} 
                onClose={this.goToAccount} 
                onChanged={this.updateUser}/>}/>
              <Route path={"verify-email/:email"} element={<ValidateEmail 
                opened={true} 
                onClose={this.goToAccount} 
                onValidate={this.updateUser}
                confirmSignUp={false}/>}/>
            </Route>
            <Route path="/organisation/:organisationId/edit" element={<MyOrganisation 
              user={this.state.user} 
              onUpdate={(organisation) => this.updateOrganisation(organisation)}
              onDelete={this.deleteOrganisation} />}/>
            <Route path="/organisation/new" element={<MyOrganisation 
              user={this.state.user} 
              onCreate={(organisation) => this.createOrganisation(organisation)} />}/>
          </Routes>
      </>
    }
    else {
      content = <>
        <Routes>
          <Route path="/" element={<SignUp onSignUp={(email) => {this.confirmSignUp(email)}}/>}>
            <Route path="signin" element={<SignIn opened={true} onClose={this.goHome} onSignIn={this.signIn}/>}/>
            <Route path="remind-password" element={<RemindPassword opened={true} onClose={this.goHome} onCodeSent={(email) => { this.changePassword(email)}}/>}/>
            <Route path="account/change-password/:email" element={<ChangePassword 
                opened={true} 
                onClose={this.goHome} 
                onChanged={this.updateUser}/>}/>
            <Route path="confirm-signup/:email" element={<ValidateEmail 
              opened={true} 
              onClose={this.goHome} 
              onValidate={this.updateUser}
              confirmSignUp={true}/>}/>
          </Route>
          <Route path="/privacy-notice" element={<PrivacyNotice opened={true} onClose={this.goHome}/>}/>
          <Route path="/about" element={<About opened={true} onClose={this.goHome}/>}/>
        </Routes>
        
      </>
    }

    return <>
      <IconContext.Provider value={{ className: "layout-icons", size: "1.75em" }}>
        <Container fluid className='g-0 main-container'>
          <aside className='sidebar d-flex flex-column flex-shrink-0 flex-grow-0 bg-dark text-light g-3'>
            <Container fluid as="header">
              <Navbar expand="lg" variant='dark'>
                <Navbar.Brand className="py-2" href="/"><img src={logo} alt="" className="logo" height="100"/></Navbar.Brand>
              </Navbar>
            </Container>
            <Container fluid as="section" className='px-0 flex-grow-1'>
            {sidebar}
            </Container>
            <Container fluid as="footer" className='py-3'>
              
            </Container>
          </aside>
          <section className='content d-flex flex-column flex-grow-1 g-3'>
            <Container fluid as="header" className="top-navigation">
              <TopNavigation 
                authenticated={this.state.authenticated} 
                organisations={Array.from(this.state.organisations.values())} 
                activeOrganisation={this.state.activeOrganisation} 
                mustCreateOrganisation={this.state.mustCreateOrganisation} 
                onChangeOrganisation={(organisation) => {this.changeOrganisation(organisation)}}
                onSignOut={this.signOut}/>
            </Container>
            <Container fluid className="flex-grow-1 d-flex justify-content-center">
              {content}
            </Container>
            <Container fluid as="footer" className='py-3 bottom-footer'>
              <Footer/>
            </Container>
          </section>
          {contract}
        </Container>

      </IconContext.Provider>
      </>
      
    }
}

export default withRouter(withTranslation()(Layout)); 