import React, {Component} from 'react';
import {Modal, Button, Form, Alert, Col, Spinner, Row } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { withRouter } from '../../util/Router';

class ValidateEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeInput: '',
      spinning: false,
      emailValidated: false,
      alreadyValidatedError: false,
      codeMismatchError: false,
      otherError: false,
      validated: false,
    };
  }

  handleCodeInput = (event) => {
    this.setState({codeInput: event.target.value});
  }

  setEmailValidated = (validated) => {
    this.setState({emailValidated: validated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setAlreadyValidatedError = (error) => {
    this.setState({alreadyValidatedError: error});
  }

  setCodeMismatchError = (error) => {
    this.setState({codeMismatchError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  validateForm = (event) => {
    event.preventDefault();
    const form = this.formRef;
    if  (form.checkValidity() === false) {
      event.stopPropagation();
    }

    this.setOtherError(false)
    this.setValidated(true);
    if (this.props.confirmSignUp) {
      this.confirmSignUp()
    } else {
      this.verify()
    }
  }

  confirmSignUp = (event) => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      Auth.confirmSignUp(this.props.params.email, this.state.codeInput)
      .then((response) => {
        this.setSpinning(false);
        this.setEmailValidated(true)
        this.props.onValidate()
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          case 'CodeMismatchException':
            this.setCodeMismatchError(true)
            break; 
          case 'UserNotFoundException':
            this.setAlreadyValidatedError(true)
            break;
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  verify = (event) => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      Auth.verifyCurrentUserAttributeSubmit('email', this.state.codeInput)
      .then((response) => {
        this.setSpinning(false);
        this.setEmailValidated(true)
        this.props.onValidate()
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          case 'CodeMismatchException':
            this.setCodeMismatchError(true)
            break; 
          case 'UserNotFoundException':
            this.setAlreadyValidatedError(true)
            break;
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {

    var submitButton = <Button type="submit" variant="primary">{this.props.t('button/label/validate_email')}</Button>
    if (this.state.spinning) {
      submitButton = <Button type="submit" variant="primary" disabled><Spinner
        as="span"
        animation="border"
        size="sm"                
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/validate_email')}</span>
        </Button>
    }

    var form = <>
    <p>{this.props.t('validate_email/description/enter_code_from_email')}</p>
    <Row className="justify-content-center form-items">
      <Form.Group as={Col} xs={12} lg={3} controlId="group-code">
        <Form.Label>{this.props.t('form/label/code')}</Form.Label>
        <Form.Control
          required
          type="code"
          placeholder={this.props.t('form/placeholder/code')}
          value={this.state.codeInput}
          onChange={this.handleCodeInput}
        />
      </Form.Group>
    </Row></>
    

    if(this.state.emailValidated || this.state.alreadyValidatedError ) {
      form = ''
      submitButton = ''
    }

    return <Modal show={this.props.opened} onHide={this.props.onClose} centered>
      <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} className="justify-content-center sign-up" ref={(formRef) => { this.formRef = formRef }}>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.t('validate_email/title/validate_email')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger' show={this.state.alreadyValidatedError}>
          {this.props.t('validate_email/failure_alert/already_validated_error')}
        </Alert>
        <Alert variant='danger' show={this.state.codeMismatchError}>
          {this.props.t('validate_email/failure_alert/code_mismatch_error')}
        </Alert>
        <Alert variant='danger' show={this.state.otherError}>
          {this.props.t('failure_alert/general_error')}
        </Alert>
        <Alert variant='success' show={this.state.emailValidated}>
          {this.props.t('validate_email/success_alert/email_validated')}
        </Alert>
      
      {form}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onClose}>
          {this.props.t('button/label/close')}
        </Button>
        {submitButton}
      </Modal.Footer>
      </Form>
    </Modal>
  }
}

ValidateEmail.propTypes = {
  email: PropTypes.string.isRequired,
  confirmSignUp: PropTypes.bool.isRequired
};

export default withTranslation()(withRouter(ValidateEmail))