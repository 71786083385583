
import React, {Component} from 'react';
import { Spinner, ProgressBar, Card, Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from "react-i18next";
import { IoMdCloudUpload } from "react-icons/io";

import Storage from '@aws-amplify/storage';

const allowedMimeTypes = ['image/jpeg', 'image/png']

class ImagesField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.value ? this.props.value : [],
      imageUrls: {},
      spinning: false,
    };

    // Generate URLs accordingly
    this.generateImageUrls();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        images: this.props.value ? this.props.value : []
      });
      this.generateImageUrls();
    }
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  generateImageUrls = () => {
    this.state.images.map((image) => {

      // Do it only if not already exists.
      if (this.state.imageUrls[image] === undefined) {
        // Get signed links
        Storage.get(image, {
          level: "protected",
          bucket: this.props.bucket,
          region: this.props.region
        })
          .then(result => {
            var imageUrls = {...this.state.imageUrls}; 
            imageUrls[image] = result
            this.setState({imageUrls: imageUrls})
          })
          .catch(err => { });
      }
    });
  }

  selectImages = (images) => {
    // TODO: Resize images and create derivates
    // TODO: Reorder image

    if (images.length > 0) {

      // Check mime type. We only accept images
      // Skip if wrong type
      var allowedImages = []
      images.map((image) => {
        if (allowedMimeTypes.includes(image.type)) {
          allowedImages.push(image)
        }
      });

      if (allowedImages.length> 0) {
        this.setSpinning(true)
        allowedImages.map((image) => {

          var mimeComponents = image.type.split('/')
          var fileName = uuidv4() + "." + mimeComponents[1];

          Storage.put(`${fileName}`, image, {
            level: "protected",
            bucket: this.props.bucket,
            region: this.props.region,
            contentType: image.type 
          })
          .then(result => {
            const images = [...this.state.images, ...[fileName]]; 
            this.setState({images: images})
            this.props.onChange(images)
            this.generateImageUrls();
            this.setSpinning(false)
          })
          .catch(err => {
            this.setSpinning(false)
          });
        }) 
      }
    }
  }

  removeImage = (image) => {
    const images = [...this.state.images];
    
    const index = images.indexOf(image);
    if (index > -1) {
      images.splice(index, 1);

      this.setState({images: images})
      this.props.onChange(images)
    }

    // Try deleting from the cloud, but don't fail if not possible.
    Storage.remove(image, {
      level: "protected",
      bucket: this.props.bucket,
      region: this.props.region,
      contentType: image.type 
    })
    .then(result => {
    })
    .catch(err => {
    });
  }

  render() {
    var content = <div className="d-flex justify-content-center flex-wrap">
      { this.state.images.map((image) => {
        if (this.state.imageUrls[image] !== undefined) {
          return <Image src={this.state.imageUrls[image]} rounded className="mx-1 mb-2" style={{height: "150px", width: "auto", cursor: "pointer"}} onClick={() => {this.removeImage(image)}}/>
        }
      }) }
    </div>

    var dropzone = <Dropzone 
      onDrop={this.selectImages} 
      maxSize={10485760} // 10 MB
      accept={allowedMimeTypes}>
      {({getRootProps, getInputProps, isDragActive}) => (<>
        <Card style={{ width: '100%' }}  {...getRootProps()}>
        <Card.Body style={{ height: '180px' }}>
          <Card.Text style={{ textAlign: 'center' }}>
            <IoMdCloudUpload color="primary" size={100}/><br/>
            { isDragActive ? this.props.t('form/placeholder/image_dropzone_drop_here') : this.props.t('form/placeholder/image_dropzone')}
          </Card.Text>
          <input {...getInputProps()} />
        </Card.Body>
      </Card></>
      )}
    </Dropzone>
    
    if (this.state.spinning) {
      dropzone = <Card style={{ width: '100%' }} >
      <Card.Body style={{ paddingTop: '75px', height: '180px' }}>
        <Card.Text style={{ textAlign: 'center' }}>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"/> {this.props.t('form/placeholder/please_wait')}
        </Card.Text>
        </Card.Body>
      </Card>
    }

    return <>
      {content}
      {dropzone}
      </>
  }
}


export default withTranslation()(ImagesField)