import React, {Component} from 'react';
import { Modal, Button, Form, Spinner, Alert} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { withTranslation } from "react-i18next";

class Contract extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signedContractInput: false,
      spinning: false,
      otherError: false,
      validated: false,
      updated: false,
    };
  }

  userToState = (user) => {
    this.setState({ signedContractInput: user.attributes['custom:signed_contract'] == 'true' ? true : false});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.attributes !== undefined) {
      this.userToState(nextProps.user);
    }
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  handleSignedContractInput = (event) => {
    this.setState({signedContractInput: event.target.checked});
  }

  validateForm = (event) => {
    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setValidated(true);
    this.save()
  }

  save = async () => {
    const form = this.formRef;

    var newAttributes = {
      'custom:signed_contract': this.state.signedContractInput ? 'true' : 'false'
    } 

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      await Auth.updateUserAttributes(this.props.user, newAttributes)
      .then(async (response) => {
        this.setUpdated(true);
        this.props.onUpdate();
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {
    var submitButton = <Button variant="primary" onClick={this.validateForm}>{this.props.t('button/label/continue')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary"  onClick={this.validateForm} disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        
        role="status"
        aria-hidden="true"/> {this.props.t('button/label/continue')}
        </Button>
    }
      return (
        <Modal show={this.props.opened} backdrop="static" centered scrollable dialogClassName="modal-lg">
          <Form noValidate validated={this.state.validated} ref={(formRef) => { this.formRef = formRef }}>
        <Modal.Header>
          <Modal.Title>{this.props.t('contract/title/contract')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='danger' show={this.state.otherError}>
            {this.props.t('failure_alert/general_error')}
          </Alert>

          {this.props.t('contract/body')}


        </Modal.Body>
        <Modal.Footer>
          
            <Form.Group className="my-3" controlId="formBasicCheckbox">
              <Form.Check 
                checked={this.state.signedContractInput}
                onChange={this.handleSignedContractInput}
                type="checkbox" 
                required
                label={this.props.t('form/label/i_agree')} />
            </Form.Group>
          {submitButton}
          
        </Modal.Footer>
        </Form> 
      </Modal>
      );
  }
}

export default withTranslation()(Contract);