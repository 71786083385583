import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import { Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { IoMdPerson, IoMdLogOut, IoMdLogIn, IoMdLock, IoMdBusiness, IoMdAdd, IoMdCheckmark} from "react-icons/io";

// Styles & resources
import './scss/top_navigation.scss';

class TopNavigation extends Component {
  constructor(props) {
    super(props); 
  }

  handleMenu = (selectedKey) => {
    switch(selectedKey) {
      case 'signout':
        this.props.onSignOut();
        break;
    }
  }

  handleDropdown = (index) => {
    if (index !== null) {
      this.props.onChangeOrganisation(this.props.organisations[index] ?? null);
    }
  }
 
  render() {
    let menuItems

    if (this.props.authenticated) {

      if (this.props.organisations.length > 0) {

        var organisationSettings = ''
        if (this.props.activeOrganisation) {
          organisationSettings = <NavLink to={"/organisation/"+this.props.activeOrganisation.organisationId+"/edit"} className="nav-link"><IoMdBusiness/> {this.props.t('navigation/link/organisation_settings')}</NavLink>
        }

        const navDropdownTitle = (<>{this.props.activeOrganisation ? this.props.activeOrganisation.name : this.props.t('navigation/link/choose_organisation')}</>);
        menuItems = <>
          <NavDropdown title={navDropdownTitle} onSelect={this.handleDropdown}>
            {this.props.organisations.map((organisation, i) => {   
              if (this.props.activeOrganisation 
                && organisation.organisationId == this.props.activeOrganisation.organisationId) {
                return (<NavDropdown.Item><Nav.Link eventKey={i} className="nav-link"><IoMdCheckmark/> {organisation.name}</Nav.Link></NavDropdown.Item>) 
              }
              else {
                return (<NavDropdown.Item><Nav.Link eventKey={i} className="nav-link"> {organisation.name}</Nav.Link></NavDropdown.Item>) 
              }
            })}
            <NavDropdown.Item><NavLink to={"/organisation/new"} className="nav-link"><IoMdAdd/> {this.props.t('navigation/link/add_organisation')}</NavLink></NavDropdown.Item>
          </NavDropdown>
          {organisationSettings}
          <NavLink to="/account" className="nav-link"><IoMdPerson/> {this.props.t('navigation/link/account_settings')}</NavLink>
          <Nav.Link eventKey="signout" className="nav-link"><IoMdLogOut/> {this.props.t('navigation/link/sign_out')}</Nav.Link>
        </>
      }
      else if (this.props.mustCreateOrganisation) {
        menuItems = <>
          <NavLink to="/organisation/new" className="nav-link"><IoMdBusiness/> {this.props.t('navigation/link/add_organisation')}</NavLink>
          <NavLink to="/account" className="nav-link"><IoMdPerson/> {this.props.t('navigation/link/account_settings')}</NavLink>
          <Nav.Link eventKey="signout" className="nav-link"><IoMdLogOut/> {this.props.t('navigation/link/sign_out')}</Nav.Link>
        </>
      }
      else {
        menuItems = <>
          <NavLink to="/account" className="nav-link"><IoMdPerson/> {this.props.t('navigation/link/account_settings')}</NavLink>
          <Nav.Link eventKey="signout" className="nav-link"><IoMdLogOut/> {this.props.t('navigation/link/sign_out')}</Nav.Link>
        </>
      }

    }
    else {
      menuItems = <>
          <NavLink to="/signin" className="nav-link"><IoMdLogIn/> {this.props.t('navigation/link/sign_in')}</NavLink>
          <NavLink to="/remind-password" className="nav-link"><IoMdLock/> {this.props.t('navigation/link/remind_password')}</NavLink>
        </>
    }
    
    return (
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
          <Nav onSelect={(selectedKey) => this.handleMenu(selectedKey)}>
            {menuItems}
          </Nav>
        </Navbar.Collapse>
        <Nav onSelect={(selectedKey) => this.handleMenu(selectedKey)} className="justify-content-end wallet-nav"></Nav>
      </Navbar>
    );
  }
}

TopNavigation.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  activeOrganisation: PropTypes.object,
  organisations: PropTypes.array,
  onChangeOrganisation: PropTypes.func,
  onSignOut: PropTypes.func
};

export default withTranslation()(TopNavigation);