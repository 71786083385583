const awsConfig = {
  "aws_project_region": "eu-west-1",
  "Auth": {
      "identityPoolId": "eu-west-1:95e102f6-6702-42e5-835f-b2826c49a32b",
      "region": "eu-west-1",
      "userPoolId": "eu-west-1_I79KAlvnV",
      "userPoolWebClientId": "6vqntpoqh75a23mi6tu1r82id7"
  },
  "API": {
      "endpoints": [
          {
              "name": "organisation",
              "endpoint": "https://hm8w8ryy5c.execute-api.eu-west-1.amazonaws.com/prod"
          },
          {
              "name": "activity",
              "endpoint": "https://svii2ojdcf.execute-api.eu-west-1.amazonaws.com/prod"
          }
      ]
  },
  "Storage": { 
    "activity": {
      "bucket": "ab-platform-activity-bucket-prod",
      "region": "eu-west-1",
    }
  }
};

export default awsConfig;