import React, {Component} from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { withTranslation } from "react-i18next";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailInput: '',
      passwordInput: '',
      spinning: false,
      authError: false,
      otherError: false,
      validated: false,
      validationError: false,
    };
  }

  handleEmailInput = (event) => {
    this.setState({emailInput: event.target.value});
  }

  handlePasswordInput = (event) => {
    this.setState({passwordInput: event.target.value});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setAuthError = (error) => {
    this.setState({authError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  close = () => {
    this.props.onClose()
  }

  validateForm = (event) => {
    event.preventDefault();
    const form = this.formRef;
    if  (form.checkValidity() === false) {
      event.stopPropagation();
    }

    this.setAuthError(false)
    this.setOtherError(false)
    this.setValidated(true);
    this.signIn()
  }

  signIn = async () => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);

      await Auth.signIn({
        username: this.state.emailInput,
        password: this.state.passwordInput,
      })
      .then((response) => {
        this.setSpinning(false);
        this.props.onSignIn();
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          case "UserNotFoundException":
          case "NotAuthorizedException":
            this.setAuthError(true)
            break;
          default:
            console.log(error)
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {
    var submitButton = <Button variant="primary" type="submit" className="my-1 mr-2">{this.props.t('button/label/sign_in')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" className="my-1 mr-2" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/sign_in')}</span>
        </Button>
    }

    return <Modal show={this.props.opened} onHide={this.props.onClose} centered>
      <Form noValidate validated={this.state.validated} ref={(formRef) => { this.formRef = formRef }} onSubmit={this.validateForm}>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.t('sign_in/title/sign_in')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger' show={this.state.authError}>
          {this.props.t('sign_in/failure_alert/incorrect_email_or_password')}
        </Alert>
        <Alert variant='danger' show={this.state.validationError}>
          {this.props.t('failure_alert/validation_error')}
        </Alert>
        <Alert variant='danger' show={this.state.otherError}>
          {this.props.t('failure_alert/general_error')}
        </Alert>
        

          <Form.Group controlId="group-email">
            <Form.Label className="my-1 mr-2" className="required">{this.props.t('form/label/email')}</Form.Label>
            <Form.Control
              required
              type="email"
              className="my-1 mr-2"
              placeholder={this.props.t('form/placeholder/email')}
              value={this.state.emailInput}
              onChange={this.handleEmailInput}
            />
          </Form.Group>

          <Form.Group controlId="group-password">
            <Form.Label className="my-1 mr-2" className="required">{this.props.t('form/label/password')}</Form.Label>
            <Form.Control
              required
              type="password"
              className="my-1 mr-2"
              value={this.state.passwordInput}
              onChange={this.handlePasswordInput}
            />
          </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onClose}>
        {this.props.t('button/label/close')}
        </Button>
        {submitButton}
      </Modal.Footer>
      </Form>
    </Modal>
  }
}

export default withTranslation()(SignIn)