import React, {Component} from 'react';
import { Modal, Button, Form, Alert, InputGroup, Col, Row, Spinner, Tabs, Tab } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { withTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withRouter } from '../../util/Router';
import { Pane } from '../../components/Pane';

class MyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstNameInput: '',
      lastNameInput: '',
      emailInput: '',
      emailVerified: false,
      spinning: false,
      changePasswordSpinning: false,
      verifyEmailSpinning: false,
      limitExceeded: false,
      aliasExists: false,
      otherError: false,
      validated: false,
      validationError: false,
      updated: false,
    };
  }

  userToState = (user) => {
    this.setState({ 
      firstNameInput: user.attributes.given_name ?? '',
      lastNameInput: user.attributes.family_name ?? '',
      emailInput: user.attributes.email ?? '',
      emailVerified: user.attributes.email_verified ?? '',
    });
  }

  componentDidMount = () => {
    if (this.props.user.attributes !== undefined) {
      this.userToState(this.props.user);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.user !== prevProps.user) {
      this.userToState(this.props.user);
    }
  }

  handleFirstNameInput = (event) => {
    this.setState({firstNameInput: event.target.value});
  }

  handleLastNameInput = (event) => {
    this.setState({lastNameInput: event.target.value});
  }

  handleEmailInput = (event) => {
    this.setState({emailInput: event.target.value});
  }

  setChangePasswordSpinning = (spinning) => {
    this.setState({changePasswordSpinning: spinning});
  }

  setVerifyEmailSpinning = (spinning) => {
    this.setState({verifyEmailSpinning: spinning});
  }

  setLimitExceeded = (exceeded) => {
    this.setState({limitExceeded: exceeded});
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setAliasExists = (exists) => {
    this.setState({aliasExists: exists});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  goHome = () => {
    this.props.navigate("/account")
  }

  validateForm = (event) => {
    event.preventDefault();
    this.setValidationError(false);

    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setValidationError(true);
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setLimitExceeded(false)
    this.setAliasExists(false)
    this.setValidated(true);
    this.save()
  }

  verifyEmail = async () => {
    this.setVerifyEmailSpinning(true);
    Auth.verifyCurrentUserAttribute('email')
    .then((response) => {
      this.setVerifyEmailSpinning(false);
      this.props.onVerifyEmail(this.props.user.attributes.email);
    })
    .catch((error) => {
      this.setVerifyEmailSpinning(false);
      switch(error.code) {
        case 'LimitExceededException':
          this.setLimitExceeded(true)
          break;
        default:
          this.setOtherError(true)
          break;
      }
    });
  }

  changePassword = async () => {
    this.setChangePasswordSpinning(true);
    Auth.forgotPassword(this.props.user.attributes.email)
    .then((response) => {
      this.setChangePasswordSpinning(false);
      this.props.onChangePassword(this.props.user.attributes.email);
    })
    .catch((error) => {
      this.setChangePasswordSpinning(false);
      switch(error.code) {
        case 'LimitExceededException':
          this.setLimitExceeded(true)
          break;
        default:
          this.setOtherError(true)
          break;
      }
    });
  }

  save = async () => {
    const form = this.formRef;

    var newAttributes = {
      email: this.state.emailInput,
      given_name: this.state.firstNameInput,
      family_name: this.state.lastNameInput,
      name: this.state.firstNameInput + " " + this.state.lastNameInput
    } 

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      await Auth.updateUserAttributes(this.props.user, newAttributes)
      .then(async (response) => {
        this.setSpinning(false);
        this.setUpdated(true);
        this.props.onUpdate();
        if (newAttributes.email !== this.props.user.attributes.email) {
          this.props.onVerifyEmail(this.props.user.attributes.email);
        }
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          case 'AliasExistsException':
            this.setAliasExists(true)
            break;
          default:
            this.setOtherError(true)
            break;
        }
      });
    }
  }

  render() {
    var changeButton = <Button variant="primary" onClick={this.changePassword} block>{this.props.t('button/label/change_password')}</Button>
    if (this.state.changePasswordSpinning) {
      changeButton = <Button variant="primary" disabled block>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> {this.props.t('button/label/change_password')}
        </Button>
    }

    var emailVerified = <InputGroup.Text id="emailVerified">{this.props.t('state/label/verified')}</InputGroup.Text>
    if (!this.state.emailVerified) {
      emailVerified = <>
        <InputGroup.Text id="emailUnverified">{this.props.t('state/label/unverified')}</InputGroup.Text>
        <Button variant="primary" id="buttonVerifyEmail"  onClick={this.verifyEmail}>
          {this.props.t('button/label/verify_email')}
        </Button>
      </>
      if (this.state.verifyEmailSpinning) {
        emailVerified = <>
        <InputGroup.Text id="emailUnverified">{this.props.t('state/label/unverified')}</InputGroup.Text>
        <Button variant="primary" id="buttonVerifyEmail" disabled block>
          <Spinner
          as="span"
          animation="border"
          size="sm"          
          role="status"
          aria-hidden="true"/> {this.props.t('button/label/verify_email')}
        </Button>
      </>
      }
    }

    var submitButton = <Button variant="primary" type="submit">{this.props.t('button/label/save')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> {this.props.t('button/label/save')}
        </Button>
    }

    return <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} ref={(formRef) => { this.formRef = formRef }}>
        <Pane 
          title={this.props.t('my_account/title/my_account')}
          operations={<>
              <Alert variant='success' show={this.state.updated}>
                {this.props.t('success_alert/updated')}
              </Alert>
              <Alert variant='danger' show={this.state.aliasExists}>
                {this.props.t('my_account/failure_alert/email_exists')}
              </Alert>
              <Alert variant='danger' show={this.state.validationError}>
                {this.props.t('failure_alert/validation_error')}
              </Alert>
              <Alert variant='danger' show={this.state.limitExceeded}>
                {this.props.t('my_account/failure_alert/limit_exceeded')}
              </Alert>
              <Alert variant='danger' show={this.state.otherError}>
                {this.props.t('failure_alert/general_error')}
              </Alert></>
          }
          value={<>
                <Form.Group as={Row} controlId="groupEmail" className="mb-3">
                  <Form.Label column sm={3} className="required">{this.props.t('form/label/email')}</Form.Label>
                  <Col sm={9}>
                    <InputGroup>
                      <Form.Control
                        type="email"
                        required
                        value={this.state.emailInput}
                        onChange={this.handleEmailInput}
                      />
                      {emailVerified}
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="groupPassword" className="mb-3">
                  <Form.Label column sm={3}>{this.props.t('form/label/password')}</Form.Label>
                  <Col sm={9}>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        readOnly
                        defaultValue="******"
                      />
                      {changeButton}
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Row>
                <Form.Group controlId="groupGivenName" as={Col}>
                <Form.Label>{this.props.t('form/label/first_name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.props.t('form/placeholder/first_name')}
                  value={this.state.firstNameInput}
                  onChange={this.handleFirstNameInput}
                />
                </Form.Group>
                <Form.Group controlId="groupLastName" as={Col}>
                <Form.Label>{this.props.t('form/label/last_name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.props.t('form/placeholder/last_name')}
                  value={this.state.lastNameInput}
                  onChange={this.handleLastNameInput}
                />
                </Form.Group>
                </Row>
          </>}
          footer={submitButton}/>
          <Outlet/>
      </Form>
  }
}

export default withRouter(withTranslation()(MyAccount))