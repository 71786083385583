import React, {Component} from 'react';
import { withTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import { withRouter } from '../../util/Router';
import { Pane } from '../Pane';

import { Activity } from '../Activity'
import { Loading } from '../Loading'

import { Calendar, momentLocalizer } from 'react-big-calendar'

import moment from 'moment'
import 'moment/min/locales';

// AWS Amplify
import { API } from 'aws-amplify';

// Styles & resources
import './scss/activitycalendar.scss';

const localizer = momentLocalizer(moment)

const events = [{
  id: 1,
  color: '#fd3153',
  from: '2019-05-02T18:00:00+00:00',
  to: '2019-05-05T19:00:00+00:00',
  title: 'This is an event'
}, {
  id: 2,
  color: '#1ccb9e',
  from: '2019-05-01T13:00:00+00:00',
  to: '2019-05-05T14:00:00+00:00',
  title: 'This is another event'
}, {
  id: 3,
  color: '#3694DF',
  from: '2019-05-05T13:00:00+00:00',
  to: '2019-05-05T20:00:00+00:00',
  title: 'This is also another event'
}];

class ActivityCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      instances: [],
      loading: false,
      spinning: false,
      deleteSpinning: false,
      aliasExists: false,
      otherError: false,
      validated: false,
      updated: false,
    };
  }

  async componentDidMount() {
    if (this.props.organisationId) {
      await this.refreshActivityInstances()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.organisationId !== prevProps.organisationId) {
      await this.refreshActivityInstances()
    }
  }

  refreshActivityInstances = async () => {
    if (this.props.organisationId) {
      this.setLoading(true)
      await API.get('activity', '/organisation/' + this.props.organisationId + '/activity', {})
      .then(response => {
        this.setLoading(false)
        this.setState({activities: response});
      })
      .catch(error => {
        this.setLoading(false)
        console.log(error)}
        )
    }
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setDeleteSpinning = (spinning) => {
    this.setState({deleteSpinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  goHome = () => {
    this.props.navigate("/activity")
  }

  createActivity = (activity) => {
    this.refreshActivityInstances()
  }

  updateActivity = (activity) => {
    this.refreshActivityInstances()
  }

  deleteActivity = () => {
    this.refreshActivityInstances()
  }

  validateForm = (event) => {
    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setValidated(true);
    this.save()
  }

  editActivityInstance = (activity) => {
    this.props.navigate("/activity/" + activity.activityId + "/edit")
  } 

  delete = async () => {
    this.setDeleteSpinning(true);
  }

  render() {
    const loading = <Loading/>

    const pane =  <Pane 
      title={this.props.t('activity_calendar/title/calendar')}
      operations={<></>}
      value={<>
        <Calendar
          localizer={localizer}
          culture='pl'
          events={this.state.instances}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          messages={{
            'today': this.props.t('activity_calendar/message/today'), 
            "previous": this.props.t('activity_calendar/message/previous'), 
            "next": this.props.t('activity_calendar/message/next'),
            "month": this.props.t('activity_calendar/message/month'),
            "week": this.props.t('activity_calendar/message/week'),
            "day": this.props.t('activity_calendar/message/day'),
            "agenda": this.props.t('activity_calendar/message/agenda'),
            "agenda": this.props.t('activity_calendar/message/agenda'),
          }}
        />
        </>}
      />

    return this.state.loading ? loading : pane

    
  }
}

export default withRouter(withTranslation()(ActivityCalendar))