import React, {Component} from 'react';
import { Modal, Button, Form, Alert, Spinner, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { withTranslation } from "react-i18next";

import { Pane } from '../../components/Pane';
import { Loading } from '../../components/Loading'

import { AddressField } from '../../components/AddressField'
import { Confirm } from '../../components/Confirm'
import { withRouter } from '../../util/Router';

// AWS Amplify
import { API } from 'aws-amplify';

const fields = {
  name: '',
  tradingName: '',
  type: '',
  registrationNumber: '',
  taxNumber: '',
  website: '',
  email: '',
  phoneNumber: '',
  address: {},
  created: '',
  updated: '',
  logo: {},
  description: '',
  tags: []
};

class MyOrganisation extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      organisationInput: {...fields},
      loading: false,
      spinning: false,
      confirm: false,
      deleteSpinning: false,
      aliasExists: false,
      otherError: false,
      validationError: false,
      validated: false,
      updated: false,
    };
  }

  async componentDidMount() {
    if (this.props.params.organisationId) {
      await this.refreshOrganisation()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.params.organisationId !== prevProps.params.organisationId) {
      await this.refreshOrganisation()
    }
  }

  refreshOrganisation = async () => {
    if (this.props.params.organisationId) {
      this.setLoading(true)
      await API.get('organisation', '/organisation/' + this.props.params.organisationId, {})
      .then(response => {
        this.setLoading(false)
        this.setState({ organisationInput: {...this.state.organisationInput, ...response} });
      })
      .catch(error => {
        this.setLoading(false)
      })
    } else {
      this.setState({ organisationInput: {...this.state.organisationInput, ...fields} });
    }
  }

  handleInput = (event, key, value) => {
    const organisationInput = Object.assign({}, this.state.organisationInput); 
    organisationInput[key] = value;
    this.setState({ organisationInput: organisationInput });
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setDeleteSpinning = (spinning) => {
    this.setState({deleteSpinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setConfirm = (confirm) => {
    this.setState({confirm: confirm});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  validateForm = (event) => {
    this.setValidationError(false);
    event.preventDefault();
    
    const form = this.formRef;
    if (form.checkValidity() === false) {
      this.setValidationError(true);
      event.stopPropagation();
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setValidated(true);
    this.save()
  }

  askConfirmation = () => {
    this.setConfirm(true)
  }

  cancelConfirmation = () => {
    this.setConfirm(false)
  }

  confirmConfirmation = () => {
    this.setConfirm(false)
    this.delete()
  }

  delete = async () => {
    this.setDeleteSpinning(true);

    await API.del('organisation', '/organisation/' + this.props.params.organisationId,  {})
    .then(async (response) => {
      this.setDeleteSpinning(false);
      this.setUpdated(true);
      this.props.onDelete();
    })
    .catch((error) => {
      this.setDeleteSpinning(false);
      switch(error.code) {
        default:
          this.setOtherError(true)
          break;
      }
    });
  }

  save = async () => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);

      if (this.props.params.organisationId) {
        var organisation = Object.assign(this.state.organisationInput, {orgnisationId: this.props.params.organisationId})
        await API.put('organisation', '/organisation/' + this.props.params.organisationId,  {body: organisation})
        .then(async (response) => {
          this.setSpinning(false);
          this.setUpdated(true);
          this.props.onUpdate(response);
        })
        .catch((error) => {
          this.setSpinning(false);
          switch(error.code) {
            default:
              this.setOtherError(true)
              break;
          }
        });
      } else {
        await API.post('organisation', '/organisation', {body: this.state.organisationInput})
        .then(async (response) => {
          this.setSpinning(false);
          this.setUpdated(true);
          this.props.onCreate(response);
        })
        .catch((error) => {
          this.setSpinning(false);
          switch(error.code) {
            default:
              this.setOtherError(true)
              break;
          }
        });
      }
    }
  }

  render() {
    const loading = <Loading/>

    var confirmForm = '';
    if (this.state.confirm) {
      confirmForm = <Confirm message={this.props.t('organisation/dialog/confirm_deletion')} opened={true} onConfirm={this.confirmConfirmation} onCancel={this.cancelConfirmation} />
    } 

    var detailsForms = <>
      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationType">
        <Form.Label column sm={3} className="required">{this.props.t('form/label/organisation_type')}</Form.Label>
        <Col sm={9}><Form.Select
          value={this.state.organisationInput.type}
          onChange={(event) => { this.handleInput(event, 'type', event.target.value) }}
        >
          <option value="private_company">{this.props.t('organisation/type/corporation')}</option>
          <option value="sole_trade">{this.props.t('organisation/type/sole_trade')}</option>
          <option value="charity">{this.props.t('organisation/type/charity')}</option>
        </Form.Select></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationName">
        <Form.Label column sm={3} className="required">{this.props.t('form/label/organisation_name')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          required
          placeholder={this.props.t('form/placeholder/organisation_name')}
          value={this.state.organisationInput.name}
          onChange={(event) => { this.handleInput(event, 'name', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationTradingName">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_trading_name')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_trading_name')}
          value={this.state.organisationInput.tradingName}
          onChange={(event) => { this.handleInput(event, 'tradingName', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationAddress">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_address')}</Form.Label>
        <Col sm={9}>
        <AddressField
          value={this.state.organisationInput.address} 
          onChange={(address) => { this.handleInput(null, 'address', address) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationNumber">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_number')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_number')}
          value={this.state.organisationInput.registrationNumber}
          onChange={(event) => { this.handleInput(event, 'registrationNumber', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationtTaxNumber">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_tax_number')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_tax_number')}
          value={this.state.organisationInput.taxNumber}
          onChange={(event) => { this.handleInput(event, 'taxNumber', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationWebsite">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_website')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_website')}
          value={this.state.organisationInput.website}
          onChange={(event) => { this.handleInput(event, 'website', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationEmail">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_email')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_email')}
          value={this.state.organisationInput.email}
          onChange={(event) => { this.handleInput(event, 'email', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" ccontrolId="groupOrganisationPhoneNumber">
        <Form.Label column sm={3}>{this.props.t('form/label/organisation_phone_number')}</Form.Label>
        <Col sm={9}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/organisation_phone_number')}
          value={this.state.organisationInput.phoneNumber}
          onChange={(event) => { this.handleInput(event, 'phoneNumber', event.target.value) }}
        /></Col>
      </Form.Group></>

    var submitButton = <Button variant="primary" type="submit">{this.props.t('button/label/save')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"/> {this.props.t('button/label/save')}
        </Button>
    }

    var content = <>{detailsForms}</>
    var deleteButton = ''
    if (this.props.params.organisationId) {
      deleteButton = <Button variant="danger" onClick={this.askConfirmation}>{this.props.t('button/label/delete')}</Button>
      if (this.state.deleteSpinning) {
        deleteButton = <Button variant="danger" onClick={this.askConfirmation} disabled>
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"/> {this.props.t('button/label/delete')}
          </Button>
      }

      content = <Tabs defaultActiveKey="details" id="organisation-tabs" className="mb-3">
        <Tab eventKey="details" title={this.props.t('tabs/label/organisation_details')}>
          {detailsForms}
        </Tab>
        <Tab eventKey="members" title={this.props.t('tabs/label/organisation_members')} disabled>
        </Tab>
        <Tab eventKey="advanced" title={this.props.t('tabs/label/organisation_advanced')} disabled>
        </Tab>
      </Tabs>
    }

    const pane = <>{confirmForm}<Form noValidate validated={this.state.validated} ref={(formRef) => { this.formRef = formRef }} onSubmit={this.validateForm}>
      
      <Pane 
        title={this.props.t('my_organisation/title/my_organisation')}
        operations={<>
        <Alert variant='success' show={this.state.updated}>
          {this.props.t('success_alert/updated')}
        </Alert>
        
        <Alert variant='danger' show={this.state.validationError}>
          {this.props.t('failure_alert/validation_error')}
        </Alert>

        <Alert variant='danger' show={this.state.otherError}>
          {this.props.t('failure_alert/general_error')}
        </Alert>
        </>
      }
      value={content}
      footer={<>{deleteButton} {submitButton}</>}/></Form></>

    return this.state.loading ? loading : pane
  }
}

export default withTranslation()(withRouter(MyOrganisation))