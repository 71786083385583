
import React, {Component} from 'react';
import { Form, Col, Row, ToggleButton, ToggleButtonGroup, InputGroup } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import countries from "i18n-iso-countries";

// TODO, support languages
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

class AddressField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressInput: {
        country: this.props.value.country ? this.props.value.country : '',
        administrativeArea: this.props.value.administrativeArea ? this.props.value.administrativeArea : '',
        postalCode: this.props.value.postalCode ? this.props.value.postalCode : '',
        city: this.props.value.city ? this.props.value.city : '',
        addressLine3: this.props.value.addressLine3 ? this.props.value.addressLine3 : '',
        addressLine2: this.props.value.addressLine2 ? this.props.value.addressLine2 : '',
        addressLine1: this.props.value.addressLine1 ? this.props.value.addressLine1 : '',
        lat: this.props.value.lat ? this.props.value.lat : '',
        lon: this.props.value.lon ? this.props.value.lon : '',
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({addressInput: this.props.value});
    }
  }

  handleInput = (event, key) => {
    const addressInput = Object.assign({}, this.state.addressInput); 
    addressInput[key] = event.target.value;
    this.setState({ addressInput: addressInput });

    this.props.onChange(addressInput);
  }

  render() {
    var c = countries.getNames("pl", {select: "official"});
    return <>
      <Form.Group as={Row} className="mb-3" controlId="groupAddressLine1">
        <Col sm={12}>
          <Form.Label className="required">{this.props.t('form/label/address_line_1')}</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder={this.props.t('form/placeholder/address_line_1')}
            value={this.state.addressInput.addressLine1}
            onChange={(event) => { this.handleInput(event, 'addressLine1') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressLine2">
        <Col sm={12}>
          <Form.Label>{this.props.t('form/label/address_line_2')}</Form.Label>
          <Form.Control
            type="text"
            placeholder={this.props.t('form/placeholder/address_line_2')}
            value={this.state.addressInput.addressLine2}
            onChange={(event) => { this.handleInput(event, 'addressLine2') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressLine3">
        <Col sm={12}>
          <Form.Label>{this.props.t('form/label/address_line_3')}</Form.Label>
          <Form.Control
            type="text"
            placeholder={this.props.t('form/placeholder/address_line_3')}
            value={this.state.addressInput.addressLine3}
            onChange={(event) => { this.handleInput(event, 'addressLine3') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressCity">
        <Col sm={12}>
          <Form.Label className="required">{this.props.t('form/label/city')}</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder={this.props.t('form/placeholder/city')}
            value={this.state.addressInput.city}
            onChange={(event) => { this.handleInput(event, 'city') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressPostcode">
        <Col sm={12}>
          <Form.Label className="required">{this.props.t('form/label/postal_code')}</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder={this.props.t('form/placeholder/postal_code')}
            value={this.state.addressInput.postalCode}
            onChange={(event) => { this.handleInput(event, 'postalCode') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressRegion">
        <Col sm={12}>
          <Form.Label>{this.props.t('form/label/administrative_area')}</Form.Label>
          <Form.Control
            type="text"
            placeholder={this.props.t('form/placeholder/administrative_area')}
            value={this.state.addressInput.administrativeArea}
            onChange={(event) => { this.handleInput(event, 'administrativeArea') }}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="groupAddressCountry">
        <Col sm={12}>
          <Form.Label className="required">{this.props.t('form/label/country')}</Form.Label>
          <Form.Select
            required
            value={this.state.addressInput.country}
            onChange={(event) => { this.handleInput(event, 'country') }}>
              <option value="">{this.props.t('form/placeholder/select_country')}</option>
            {
              Object.keys(c).map((k, i) => {
                return <option value={k}>{c[k]}</option>
              })
            }

            </Form.Select>
        </Col>
      </Form.Group> 
    </>
  }
}


export default withTranslation()(AddressField)