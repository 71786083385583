
import React, {Component} from 'react';
import { Form, Button } from 'react-bootstrap';
import { withTranslation } from "react-i18next";


class TimesField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInput: '',
      times: this.props.value ? this.props.value : []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        times: this.props.value ? this.props.value : []
      });
    }
  }

  handleTimeInput = (event) => {
    this.setState({timeInput: event.target.value});
  }

  selectTime = (event) => {
    if (event.target.value) {
      const times = [...this.state.times]; 
      times.push(event.target.value);

      // Make unique and sort
      const utimes = [...new Set(times)];
      utimes.sort(function(a,b){
        var dateA = new Date("1970-01-01T" + a + ":00");
        var dateB = new Date("1970-01-01T" + b + ":00");
        return dateA - dateB;
      });

      this.setState({timeInput: ''})
      this.setState({times: utimes})
      this.props.onChange(utimes)
    }
  }

  render() {
    var content = this.state.times.map((value) =>
      <Button variant="primary" style={{ marginRight: 5,  marginBottom: 5 }}>{value}</Button>
    );
    
    return <>
      {content}
      <Form.Control
        style={{display: "inline", width: "auto"}}
        type="time"
        placeholder={this.props.t('form/placeholder/activity_time')}
        value={this.state.timeInput}
        onChange={this.handleTimeInput}
        onBlur={this.selectTime}/></>
  }
}


export default withTranslation()(TimesField)