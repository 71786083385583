import React, {Component} from 'react';
import { Modal, Button, Form, Alert, Spinner, InputGroup, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { withRouter } from '../../util/Router';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Loading } from '../../components/Loading'
import { DatesField } from '../../components/DatesField'
import { TimesField } from '../../components/TimesField'
import { ImagesField } from '../../components/ImagesField'
import { AddressField } from '../../components/AddressField'
import { Confirm } from '../../components/Confirm'
import awsConfig from '../../awsConfig';

// AWS Amplify
import { API } from 'aws-amplify';

class Activity extends Component {
  constructor(props) {
    super(props);

    var rrule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: new Date(),
    })

    this.state = {
      activityInput: {
        type: 'classroom',
        name: '',
        description: '',
        price: '',
        currencyCode: 'PLN',
        priceType: '',
        fromAge: '',
        toAge: '',
        instructor: '',
        instructorBio: '',
        instructorImages: [],
        images: [],
        rrule: rrule.toString(),
        duration: [],
        availability: '',
        categories: [],
        timeslots: [],
        link: '',
        website: '',
        email: '',
        phoneNumber: '',
        location: {},
        created: '',
        updated: '',
        published: '',
        guardian: '',
        tags: []
      },
      loading: false,
      spinning: false,
      deleteSpinning: false,
      confirm: false,
      aliasExists: false,
      otherError: false,
      validated: false,
      validationError: false,
      updated: false,
    };

    this.editor = null
  }

  async componentDidMount() {
    if (this.props.params.activityId) {
      await this.refreshActivity()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.params.activityId !== prevProps.params.activityId) {
      await this.refreshActivity()
    }
  }

  refreshActivity = async () => {
    if (this.props.params.activityId) {
      this.setLoading(true)
      await API.get('activity', '/organisation/' + this.props.organisationId + '/activity/' + this.props.params.activityId, {})
      .then(response => {
        this.setState({ activityInput: {...this.state.activityInput, ...response} }, () => {
          this.setLoading(false)
        });
      })
      .catch(error => {
        this.setLoading(false)
      })
    }
  }

  handleInput = (event, key, value) => {
    const activityInput = Object.assign({}, this.state.activityInput); 
    activityInput[key] = value;
    this.setState({ activityInput: activityInput });
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setDeleteSpinning = (spinning) => {
    this.setState({deleteSpinning: spinning});
  }

  setUpdated = (updated) => {
    this.setState({updated: updated});
  }

  setConfirm = (confirm) => {
    this.setState({confirm: confirm});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  askConfirmation = () => {
    this.setConfirm(true)
  }

  cancelConfirmation = () => {
    this.setConfirm(false)
  }

  confirmConfirmation = () => {
    this.setConfirm(false)
    this.delete()
  }

  close = () => {
    this.props.onClose()
  }

  validateForm = (event) => {
    event.preventDefault();
    this.setValidationError(false);

    const form = this.formRef;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setValidationError(true);
    }

    this.setUpdated(false);
    this.setOtherError(false);
    this.setValidated(true);
    this.save()
  }

  delete = async () => {
    this.setDeleteSpinning(true);

    await API.del('activity', '/organisation/' + this.props.organisationId + '/activity/' + this.props.params.activityId,  {})
    .then(async (response) => {
      this.setDeleteSpinning(false);
      this.setUpdated(true);
      this.props.onDelete();
      this.props.onClose();
    })
    .catch((error) => {
      this.setDeleteSpinning(false);
      switch(error.code) {
        default:
          this.setOtherError(true)
          break;
      }
    });
  }

  save = async () => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);

      if (this.props.params.activityId) {
        var activity = Object.assign(this.state.activityInput, {orgnisationId: this.props.params.activityId})
        await API.put('activity', '/organisation/' + this.props.organisationId + '/activity/' + this.props.params.activityId,  {body: activity})
        .then(async (response) => {
          this.setSpinning(false);
          this.setUpdated(true);
          this.props.onUpdate(response);
          this.props.onClose();
        })
        .catch((error) => {
          this.setSpinning(false);
          switch(error.code) {
            default:
              this.setOtherError(true)
              break;
          }
        });
      } else {
        await API.post('activity', '/organisation/' + this.props.organisationId + '/activity', {body: this.state.activityInput})
        .then(async (response) => {
          this.setSpinning(false);
          this.setUpdated(true);
          this.props.onCreate(response);
          this.props.onClose();
        })
        .catch((error) => {
          this.setSpinning(false);
          switch(error.code) {
            default:
              this.setOtherError(true)
              break;
          }
        });
      }
    }
  }

  render() {
    const editorConfiguration = {
      toolbar: [ 'heading', '|',  'bold', 'italic', '|', 'numberedList', 'bulletedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ]
    };

    var confirmForm = '';
    if (this.state.confirm) {
      confirmForm = <Confirm message={this.props.t('activity/dialog/confirm_deletion')} opened={true} onConfirm={this.confirmConfirmation} onCancel={this.cancelConfirmation} />
    } 

    var detailsForms = <>
      <Form.Group as={Row} className="mb-3" controlId="groupActivityType">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_type')}</Form.Label>
        <Col sm={8}><Form.Select
          value={this.state.activityInput.type}
          required
          onChange={(event) => { this.handleInput(event, 'type', event.target.value) }}
        >
          <option value="classroom">{this.props.t('activity/type/classroom')}</option>
          <option value="live">{this.props.t('activity/type/live')}</option>
          <option value="pre_recorded">{this.props.t('activity/type/pre_recorded')}</option>
        </Form.Select></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityName">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_name')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          required
          placeholder={this.props.t('form/placeholder/activity_name')}
          value={this.state.activityInput.name}
          onChange={(event) => { this.handleInput(event, 'name', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityPrice">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_price')}</Form.Label>
        <Col sm={4}><InputGroup>
        <Form.Control
            type="number"
            required
            placeholder={this.props.t('form/placeholder/activity_price')}
            value={this.state.activityInput.price}
            onChange={(event) => { this.handleInput(event, 'price', event.target.value) }}/>
            <InputGroup.Text id="minutes">{this.props.t('form/suffix/currency_pln')}</InputGroup.Text>
        </InputGroup></Col>
        <Col sm={4}><Form.Select
          value={this.state.activityInput.priceType}
          required
          onChange={(event) => { this.handleInput(event, 'priceType', event.target.value) }}
        >
        <option value="per_course">{this.props.t('activity/price_type/per_course')}</option>
        <option value="per_session">{this.props.t('activity/price_type/per_session')}</option>
        </Form.Select></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityDescription">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_description')}</Form.Label>
        <Col sm={8}>
          <CKEditor
              editor={ ClassicEditor }
              data={this.state.activityInput.description}
              required
              height={500}
              config={editorConfiguration}
              onReady={ editor => {
                  this.editor = editor
                  console.log( 'Editor is ready to use!', editor );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  this.handleInput(event, 'description', data)
              } }
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityImages">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_images')}</Form.Label>
        <Col sm={8}>
          <ImagesField
          value={this.state.activityInput.images} 
          bucket={awsConfig.Storage.activity.bucket}
          region={awsConfig.Storage.activity.region}
          onChange={(images) => { this.handleInput(null, 'images', images) }} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityAgeGroup">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_age_group')}</Form.Label>
        <Col sm={4}><Form.Control
          type="number"
          placeholder={this.props.t('form/placeholder/from_age')}
          value={this.state.activityInput.fromAge}
          onChange={(event) => { this.handleInput(event, 'fromAge', event.target.value) }}
        /></Col>
        <Col sm={4}><Form.Control
          type="number"
          placeholder={this.props.t('form/placeholder/to_age')}
          value={this.state.activityInput.toAge}
          onChange={(event) => { this.handleInput(event, 'toAge', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityGuardian">
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}><Form.Check
          name="guardian"
          label={this.props.t('form/label/guardian')}
          checked={this.state.activityInput.guardian}
          onChange={(event) => { this.handleInput(event, 'guardian', event.target.checked) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityWebsite">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_website')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/activity_website')}
          value={this.state.activityInput.website}
          onChange={(event) => { this.handleInput(event, 'website', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityEmail">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_email')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/activity_email')}
          value={this.state.activityInput.email}
          onChange={(event) => { this.handleInput(event, 'email', event.target.value) }}
        /></Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="groupActivityPhoneNumber">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_phone_number')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/activity_phone_number')}
          value={this.state.activityInput.phoneNumber}
          onChange={(event) => { this.handleInput(event, 'phoneNumber', event.target.value) }}
        /></Col>
      </Form.Group>
    </>

    var datesForm = <>
    <Form.Group as={Row} className="mb-3" controlId="groupActivityDates">
      <Form.Label column sm={4}>{this.props.t('form/label/activity_dates')}</Form.Label>
      <Col sm={8}>
        <DatesField
          value={this.state.activityInput.rrule} 
          onChange={(rrule) => { this.handleInput(null, 'rrule', rrule) }} />
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="groupActivityTime">
      <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_times')}</Form.Label>
      <Col sm={8}>
       <TimesField
          required
          value={this.state.activityInput.timeslots} 
          onChange={(timeslots) => { this.handleInput(null, 'timeslots', timeslots) }} />
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="groupActivityDuration">
      <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_duration')}</Form.Label>
      <Col sm={8}>
      <InputGroup>
       <Form.Control
          type="number"
          required
          placeholder={this.props.t('form/placeholder/activity_duration')}
          value={this.state.activityInput.duration}
          onChange={(event) => { this.handleInput(event, 'duration', event.target.value) }}/>
          <InputGroup.Text id="minutes">{this.props.t('form/suffix/minutes')}</InputGroup.Text>
      </InputGroup>
      </Col>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="groupActivityAvailablity">
      <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_availability')}</Form.Label>
      <Col sm={8}>
      <InputGroup>
        <Form.Control
          type="number"
          required
          placeholder={this.props.t('form/placeholder/activity_availability')}
          value={this.state.activityInput.availability}
          onChange={(event) => { this.handleInput(event, 'availability', event.target.value) }}/>
        <InputGroup.Text id="persons">{this.props.t('form/suffix/persons')}</InputGroup.Text>
      </InputGroup>
        

      </Col>
    </Form.Group></>

    var locationForm = <>
    <Form.Group as={Row} className="mb-3" controlId="groupActivityLocation">
      <Form.Label column sm={4}>{this.props.t('form/label/activity_address')}</Form.Label>
      <Col sm={8}>
        <AddressField
        value={this.state.activityInput.location} 
        onChange={(location) => { this.handleInput(null, 'location', location) }} /> </Col>
    </Form.Group></>


    var instructorForm = <><Form.Group as={Row} className="mb-3" controlId="groupInstructor">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_instructor')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          required
          placeholder={this.props.t('form/placeholder/activity_instructor')}
          value={this.state.activityInput.instructor}
          onChange={(event) => { this.handleInput(event, 'instructor', event.target.value) }}
        /></Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="groupInstructorBio">
        <Form.Label column sm={4} className="required">{this.props.t('form/label/activity_instructor_bio')}</Form.Label>
        <Col sm={8}>
          <CKEditor
              editor={ ClassicEditor }
              data={this.state.activityInput.instructorBio}
              required
              height={500}
              config={editorConfiguration}
              onReady={ editor => {
                  this.editor = editor
                  console.log( 'Editor is ready to use!', editor );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  this.handleInput(event, 'instructorBio', data)
              } }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="groupInstructorImage">
        <Form.Label column sm={4}>{this.props.t('form/label/instructor_images')}</Form.Label>
        <Col sm={8}>
          <ImagesField
          value={this.state.activityInput.instructorImages} 
          bucket={awsConfig.Storage.activity.bucket}
          region={awsConfig.Storage.activity.region}
          onChange={(images) => { this.handleInput(null, 'instructorImages', images) }} />
        </Col>
      </Form.Group></>


    var materialForm = <Form.Group as={Row} className="mb-3" controlId="groupActivityLink">
        <Form.Label column sm={4}>{this.props.t('form/label/activity_link')}</Form.Label>
        <Col sm={8}><Form.Control
          type="text"
          placeholder={this.props.t('form/placeholder/activity_link')}
          value={this.state.activityInput.link}
          onChange={(event) => { this.handleInput(event, 'link', event.target.value) }}
        /></Col>
      </Form.Group>

    var content = <>{confirmForm}
        <Tabs defaultActiveKey="details" id="activity-tabs" className="mb-3">
        <Tab eventKey="details" title={this.props.t('tabs/label/activity_details')}>
          {detailsForms}
        </Tab>
        <Tab eventKey="instructor" title={this.props.t('tabs/label/activity_instructor')}>
          {instructorForm}
        </Tab>
        { ['classroom', 'live'].includes(this.state.activityInput.type)  && <Tab eventKey="slots" title={this.props.t('tabs/label/activity_dates_times')}>
          {datesForm}
        </Tab> }
        { ['classroom', 'live'].includes(this.state.activityInput.type) && <Tab eventKey="location" title={this.props.t('tabs/label/location')}>
          {locationForm}
        </Tab> }
        <Tab eventKey="materials" title={this.props.t('tabs/label/materials')}>
          {materialForm}
        </Tab>
      </Tabs>
      </>
      

    var deleteButton = ''
    if (this.props.params.activityId) {
      deleteButton = <Button variant="danger" onClick={this.askConfirmation}>{this.props.t('button/label/delete')}</Button>
      if (this.state.deleteSpinning) {
        deleteButton = <Button variant="danger"  onClick={this.askConfirmation} disabled>
          <Spinner
          as="span"
          animation="border"
          size="sm"          
          role="status"
          aria-hidden="true"/> {this.props.t('button/label/delete')}
          </Button>
      }
    }

    var submitButton = <Button variant="primary" type="submit">{this.props.t('button/label/save')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary" type="submit" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"/> {this.props.t('button/label/save')}
        </Button>
    }

    const loading =  <Modal.Body><Loading/></Modal.Body>

    const modal = <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} ref={(formRef) => { this.formRef = formRef }}>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.t('activity/title/activity')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger' show={this.state.validationError}>
          {this.props.t('failure_alert/validation_error')}
        </Alert>

        <Alert variant='danger' show={this.state.otherError}>
          {this.props.t('failure_alert/general_error')}
        </Alert>
        {content}
      </Modal.Body>
      <Modal.Footer>
        {deleteButton}
        <Form.Check
            name="published"
            label={this.props.t('form/label/publish')}
            checked={this.state.activityInput.published}
            onChange={(event) => { this.handleInput(event, 'published', event.target.checked) }}
          />
        {submitButton}
      </Modal.Footer>
      </Form>

    return <Modal show={this.props.opened} onHide={this.props.onClose} backdrop="static" centered dialogClassName="modal-lg"> {this.state.loading ? loading : modal} </Modal>
  }
}

export default withTranslation()(withRouter(Activity))