
import React, {Component} from 'react';
import { Form, Col, Row, ToggleButton, ToggleButtonGroup, InputGroup } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { RRule, RRuleSet, rrulestr } from 'rrule'

// Styles & resources
import './scss/datesfield.scss';

class DatesField extends Component {
  constructor(props) {
    super(props);

    var rrule = RRule.fromString(this.props.value)

    this.state = {
      intervalInput: {
        frequency: rrule.options.freq, 
        interval: rrule.options.interval},
      startDateInput: rrule.options.dtstart ? rrule.options.dtstart.toISOString().substring(0, 10) : '',
      untilDateInput: rrule.options.until ? rrule.options.until.toISOString().substring(0, 10) : '',
      weekdaysInput: rrule.options.byweekday,
      monthDaysInput: rrule.options.bymonthday,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      var rrule = RRule.fromString(this.props.value)

      this.setState({
        intervalInput: {
          frequency: rrule.options.freq, 
          interval: rrule.options.interval},
        startDateInput: rrule.options.dtstart ? rrule.options.dtstart.toISOString().substring(0, 10) : '',
        untilDateInput: rrule.options.until ? rrule.options.until.toISOString().substring(0, 10) : '',
        weekdaysInput: rrule.options.byweekday,
        monthDaysInput: rrule.options.bymonthday,
      });

    }
  }

  handleWeekdaysInput = (value) => {
    this.setState({weekdaysInput: value}, this.changed);
  }

  handleMonthDaysInput = (value) => {
    this.setState({monthDaysInput: value}, this.changed);
  }

  handleIntervalInput = (event) => {
    this.setState({intervalInput: JSON.parse(event.target.value)}, this.changed);
  }

  handleStartDateInput = (event) => {
    this.setState({startDateInput: event.target.value}, this.changed);
  }

  handleUntilDateInput = (event) => {
    this.setState({untilDateInput: event.target.value}, this.changed);
  }

  changed = () => {
    const rrule = new RRule({
      dtstart: this.state.startDateInput && new Date(this.state.startDateInput),
      freq: this.state.intervalInput && this.state.intervalInput.frequency,
      interval: this.state.intervalInput && this.state.intervalInput.interval,
      byweekday: this.state.intervalInput.frequency == RRule.WEEKLY ? this.state.weekdaysInput : [],
      bymonthday: this.state.intervalInput.frequency == RRule.MONTHLY ? this.state.monthDaysInput : [],
      until: this.state.untilDateInput ? new Date(this.state.untilDateInput) : null
    })

    this.props.onChange(rrule.toString())
  }

  render() {
    return <>
      <Form.Group as={Row} className="mb-3" controlId="groupDateStart">
        <Col sm={8}>
        <Form.Label>{this.props.t('form/label/activity_start_date')}</Form.Label>
        <Form.Control
          type="date"
          placeholder={this.props.t('form/placeholder/activity_start_date')}
          value={this.state.startDateInput}
          required
          onChange={this.handleStartDateInput}/></Col>
        <Col sm={4}>
          <Form.Label>{this.props.t('form/label/activity_interval')}</Form.Label>
          <Form.Select 
            aria-label="Default select example"
            value={JSON.stringify(this.state.intervalInput)}
            onChange={this.handleIntervalInput}
            >
            <option value={JSON.stringify({frequency: RRule.DAILY, interval: 0})}>{this.props.t('form/option/no_repeat')}</option>
            <option value={JSON.stringify({frequency: RRule.DAILY, interval: 1})}>{this.props.t('form/option/daily')}</option>
            <option value={JSON.stringify({frequency: RRule.WEEKLY, interval: 1})}>{this.props.t('form/option/weekly')}</option>
            <option value={JSON.stringify({frequency: RRule.WEEKLY, interval: 2})}>{this.props.t('form/option/biweekly')}</option>
            <option value={JSON.stringify({frequency: RRule.WEEKLY, interval: 3})}>{this.props.t('form/option/every_3_weeks')}</option>
            <option value={JSON.stringify({frequency: RRule.WEEKLY, interval: 4})}>{this.props.t('form/option/every_4_weeks')}</option>
            <option value={JSON.stringify({frequency: RRule.MONTHLY, interval: 1})}>{this.props.t('form/option/monthly')}</option>
            <option value={JSON.stringify({frequency: RRule.MONTHLY, interval: 2})}>{this.props.t('form/option/bimonthly')}</option>
            <option value={JSON.stringify({frequency: RRule.MONTHLY, interval: 3})}>{this.props.t('form/option/every_3_months')}</option>
            <option value={JSON.stringify({frequency: RRule.MONTHLY, interval: 4})}>{this.props.t('form/option/every_4_months')}</option>
            <option value={JSON.stringify({frequency: RRule.MONTHLY, interval: 6})}>{this.props.t('form/option/every_6_months')}</option>
          </Form.Select>        
        </Col>
      </Form.Group>

      { this.state.intervalInput.frequency == RRule.WEEKLY && <Form.Group as={Row} className="mb-3" controlId="groupWeekDays">
        <Col sm={12}>
          <Form.Label>{this.props.t('form/label/activity_weekdays')}</Form.Label>
          <ToggleButtonGroup type="checkbox" className="me-2 weekday-picker" aria-label="First group" value={this.state.weekdaysInput} onChange={this.handleWeekdaysInput}>
            <ToggleButton type="checkbox" id={1} value={1} variant="outline-primary">{this.props.t('mon')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={2} value={2} variant="outline-primary">{this.props.t('tue')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={3} value={3} variant="outline-primary">{this.props.t('wed')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={4} value={4} variant="outline-primary">{this.props.t('thu')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={5} value={5} variant="outline-primary">{this.props.t('fri')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={6} value={6} variant="outline-primary">{this.props.t('sat')}</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={0} value={0} variant="outline-primary">{this.props.t('sun')}</ToggleButton>{' '}
          </ToggleButtonGroup>
          </Col>
      </Form.Group> }

      { this.state.intervalInput.frequency == RRule.MONTHLY && <Form.Group as={Row} className="mb-3" controlId="groupMonthDays">
        <Col sm={12}>
          <Form.Label>{this.props.t('form/label/activity_monthdays')}</Form.Label>
          <ToggleButtonGroup type="checkbox" className="me-2 month-day-picker" aria-label="First group" value={this.state.monthDaysInput} onChange={this.handleMonthDaysInput} 
            style={{width: "100%", display: "flex", flexWrap: "wrap"}}>
            <ToggleButton type="checkbox" id={1} value={1} variant="outline-primary">1</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={2} value={2} variant="outline-primary">2</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={3} value={3} variant="outline-primary">3</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={4} value={4} variant="outline-primary">4</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={5} value={5} variant="outline-primary">5</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={6} value={6} variant="outline-primary">6</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={7} value={7} variant="outline-primary">7</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={8} value={8} variant="outline-primary">8</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={9} value={9} variant="outline-primary">9</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={10} value={10} variant="outline-primary">10</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={11} value={11} variant="outline-primary">11</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={12} value={12} variant="outline-primary">12</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={13} value={13} variant="outline-primary">13</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={14} value={14} variant="outline-primary">14</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={15} value={15} variant="outline-primary">15</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={16} value={16} variant="outline-primary">16</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={17} value={17} variant="outline-primary">17</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={18} value={18} variant="outline-primary">18</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={19} value={19} variant="outline-primary">19</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={20} value={20} variant="outline-primary">20</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={21} value={21} variant="outline-primary">21</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={22} value={22} variant="outline-primary">22</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={23} value={23} variant="outline-primary">23</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={24} value={24} variant="outline-primary">24</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={25} value={25} variant="outline-primary">25</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={26} value={26} variant="outline-primary">26</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={27} value={27} variant="outline-primary">27</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={28} value={28} variant="outline-primary">28</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={29} value={29} variant="outline-primary">29</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={30} value={30} variant="outline-primary">30</ToggleButton>{' '}
            <ToggleButton type="checkbox" id={31} value={31} variant="outline-primary">31</ToggleButton>{' '}
          </ToggleButtonGroup>
          </Col>
      </Form.Group>} 

      { this.state.intervalInput.interval > 0 && <Form.Group as={Row} className="mb-3" controlId="groupDateUntil">
        <Col sm={8}>
        <Form.Label>{this.props.t('form/label/activity_until_date')}</Form.Label>
        <Form.Control
          type="date"
          placeholder={this.props.t('form/placeholder/activity_until_date')}
          value={this.state.untilDateInput}
          onChange={this.handleUntilDateInput}/></Col>
      </Form.Group> }
    </>
  }
}


export default withTranslation()(DatesField)