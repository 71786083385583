import React, {Component} from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { withTranslation } from "react-i18next";

class RemindPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailInput: '',
      otherError: false,
      validated: false,
      validationError: false,
      spinning: false,
      sent: false,
    };
  }

  handleEmailInput = (event) => {
    this.setState({emailInput: event.target.value});
  }

  setValidated = (validated) => {
    this.setState({validated: validated});
  }

  setValidationError = (error) => {
    this.setState({validationError: error});
  }

  setOtherError = (error) => {
    this.setState({otherError: error});
  }

  setSpinning = (spinning) => {
    this.setState({spinning: spinning});
  }

  setSent = (sent) => {
    this.setState({sent: sent});
  }

  validateForm = (event) => {
    event.preventDefault();
    const form = this.formRef;
    if  (form.checkValidity() === false) {
      event.stopPropagation();
    }

    this.setSent(false)
    this.setOtherError(false)
    this.setValidated(true);
    this.remind()
  }

  remind = (event) => {
    const form = this.formRef;

    if (form.checkValidity() === true) {
      this.setSpinning(true);
      Auth.forgotPassword(this.state.emailInput)
      .then((response) => {
        this.setSpinning(false);
        this.setSent(true)
        this.props.onCodeSent(this.state.emailInput);
        // TODO: Do we need to handle sign in case here? We should be listening on Amplify events instead and update the store of some kind.
      })
      .catch((error) => {
        this.setSpinning(false);
        switch(error.code) {
          default:
            this.setSent(true)
            break;
        }
      });
    }
  }

  render() {
    var submitButton = <Button variant="primary"  className="my-1 mr-2" type="submit">{this.props.t('button/label/remind_password')}</Button>
    if (this.state.spinning) {
      submitButton = <Button variant="primary"  className="my-1 mr-2" type="submit" disabled>
        <Spinner
        as="span"
        animation="border"
        size="sm"        
        role="status"
        aria-hidden="true"/> <span className="sr-only">{this.props.t('button/label/remind_password')}</span>
        </Button>
    }

    return <Modal show={this.props.opened} onHide={this.props.onClose} centered>

    <Form noValidate validated={this.state.validated} onSubmit={this.validateForm} ref={(formRef) => { this.formRef = formRef }}>
    <Modal.Header closeButton>
      <Modal.Title>{this.props.t('remind_password/title/remind_password')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Alert variant='success' show={this.state.sent}>
      {this.props.t('remind_password/success_alert/check_your_email_for_instructions')}
    </Alert>
    <Alert variant='danger' show={this.state.validationError}>
      {this.props.t('failure_alert/validation_error')}
    </Alert>

      <Form.Group controlId="group-email">
        <Form.Label className="my-1 mr-2" className="required">{this.props.t('form/label/email')}</Form.Label>
        <Form.Control
          required
          type="email"
          className="my-1 mr-2"
          placeholder={this.props.t('form/placeholder/email')}
          value={this.state.emailInput}
          onChange={this.handleEmailInput}
        />
      </Form.Group>
    </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onClose}>
          {this.props.t('button/label/close')}
        </Button>
        {submitButton}
      </Modal.Footer>
    </Form>
    </Modal>
  }
}

export default withTranslation()(RemindPassword);